@tailwind base;
@tailwind components;
@tailwind utilities;

/* This code is for app component */
.main-nav {
    @apply transform duration-300 hover:bg-sky-700 p-2 focus:outline-none focus:ring-2 focus:bg-sky-700;
}

.mobile-nav {
  @apply px-4 py-2 text-sm text-gray-100 hover:bg-sky-800 hover:text-gray-100 transform duration-300;
}
